/**
 * Makes scroll smooth.
 */
$('[data-toggle="smooth-scroll"]').on('click', function (e) {
    e.preventDefault();

    const hash = this.hash;

    $('html, body').animate({
        scrollTop: $(hash).offset().top
    }, 1000, function () {
        window.location.hash = hash;
    });
});