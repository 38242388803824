import Translator from 'bazinga-translator';

window.addEventListener('load', function () {
    window.cookieconsent.initialise({
        'palette': {
            'popup': {
                'background': '#edeff5',
                'text': '#838391'
            },
            'button': {
                'background': 'transparent',
                'text': '#4b81e8',
                'border': '#4b81e8'
            }
        },
        'showLink': false,
        'position': 'bottom-right',
        'type': 'opt-in',
        'content': {
            'message': Translator.trans('cookie.text'),
            'deny': Translator.trans('cookie.deny'),
            'allow': Translator.trans('cookie.allow'),
            'policy': Translator.trans('cookie.policy')
        }
    })
});