import Swal from 'sweetalert2';
import Translator from 'bazinga-translator';

$('[data-popup="terms_and_conditions"]').on('click', function () {
    Swal.fire({
        title: Translator.trans('terms_and_conditions.title'),
        type: 'info',
        width: '80%',
        html: $('#terms_and_conditions').html(),
        showCloseButton: true
    })
});

$('[data-popup="privacy_policy"]').on('click', function () {
    Swal.fire({
        title: Translator.trans('privacy_policy.title'),
        type: 'info',
        width: '80%',
        html: $('#privacy_policy').html(),
        showCloseButton: true
    })
});