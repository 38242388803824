/**
 * When the user scrolls down 80px from the top of the document, resize the navbar's padding and the logo's font size
 */
window.onscroll = function () {
    scrollFunction()
};

$(document).ready(function () {
    scrollFunction();
});

function scrollFunction() {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        document.getElementById('navigation').classList.add('shrinked');
    } else {
        document.getElementById('navigation').classList.remove('shrinked');
    }
}