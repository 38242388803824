"use strict";

// vendor js
require('bootstrap/dist/js/bootstrap.bundle.min.js');
require('sweetalert2/dist/sweetalert2.all.min.js');
require('cookieconsent/build/cookieconsent.min.js');
require('bazinga-translator');

// vendor css
require('bootstrap/dist/css/bootstrap.min.css');
require('@fortawesome/fontawesome-free/css/all.min.css');
require('sweetalert2/dist/sweetalert2.min.css');
require('cookieconsent/build/cookieconsent.min.css');


// js files
require('./components/cookie_consent.js');
require('./components/navbar_shrink.js');
require('./components/smooth_scroll.js');
require('./components/sticky_navbar.js');
require('./components/sweetalert_popup.js');

// scss files
require('../css/fos_user.scss');
require('../css/sticky_navbar.scss');
require('../css/app.scss');