/**
 * Sticky navbar to show navbar on the top after user scrolls down.
 *
 * Based on: https://gist.github.com/sarvar/79ecdc7c97bbdc9d6debf94fc19e57cb
 */
$(document).ready(function () {
    const stickyToggle = function (sticky, stickyWrapper, scrollElement) {
        const stickyHeight = sticky.outerHeight();
        const stickyTop = stickyWrapper.offset().top;

        if (scrollElement.scrollTop() >= stickyTop) {
            stickyWrapper.height(stickyHeight);
            sticky.addClass("is-sticky");
        } else {
            sticky.removeClass("is-sticky");
            stickyWrapper.height('auto');
        }
    };

    // Find all data-toggle="sticky-onscroll" elements
    $('[data-toggle="sticky-onscroll"]').each(function () {
        const sticky = $(this);

        // Insert hidden element to maintain actual top offset on page
        const stickyWrapper = $('<div>').addClass('sticky-wrapper');
        sticky.before(stickyWrapper);
        sticky.addClass('sticky');

        // Scroll & resize events
        $(window).on('scroll.sticky-onscroll resize.sticky-onscroll', function () {
            stickyToggle(sticky, stickyWrapper, $(this));
        });

        // On page load
        stickyToggle(sticky, stickyWrapper, $(window));
    });
});
